@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;

  /* background-color: #220447; */
  /* font-family:
    'Press Start 2P',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif; */
}

.gradient-bg {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 69%,
    rgba(43, 36, 111, 1) 100%,
    rgba(108, 92, 231, 1) 100%,
    rgba(2, 0, 36, 1) 100%,
    rgba(108, 92, 231, 1) 100%
  );
}

.glass {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.masonry-grid {
  column-count: 3;
  column-gap: 1rem;
}

.masonry-grid-item {
  break-inside: avoid;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .masonry-grid {
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .masonry-grid {
    column-count: 1;
  }
}

.relativeContainer {
  position: relative;
}
.relativeContainer::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    /* Black with full opacity */ rgba(0, 0, 0, 0) 15%,
    /* Transparent black, creating the fade effect */ rgba(0, 0, 0, 0) 85%,
    /* Transparent black, creating the fade effect */ rgba(0, 0, 0, 1) 100%
      /* Black with full opacity */
  );
  pointer-events: none;
}

.imageFadeIn {
  opacity: 1;
  visibility: visible;
  transition:
    opacity 2s,
    visibility 2s;
}

.imageFadeOut {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 2s,
    visibility 2s;
}

.footer-bottom-cta {
  background: radial-gradient(50% 50% at 50% 50%, #8571ff5e 0%, #05020e00 100%);
  background-position-y: 150px;
  background-size: 100% calc(100% - 140px);
  background-repeat: no-repeat;
  text-align: center;
  padding: 30px 20px; /* Adjust as needed */
  color: white; /* Adjust the color as needed */
}

.footer-bottom-cta h2 {
  margin-top: 20px; /* Adjust as needed */
  font-size: 2em; /* Adjust as needed */
}

.footer-bottom-cta p {
  margin-bottom: 20px; /* Adjust as needed */
  font-size: 1.25em; /* Adjust as needed */
}

.cta-button {
  /* Style your button */
  background-color: #6c5ce7;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #5a51d6;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  padding: 20px;
  border-radius: 12px;
  margin: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
